import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ location })  => (
    <Layout>
        <SEO 
            pagetitle="contact - お問い合わせ"
            pagepath={location.pathname}
        />
        <main id="contact">
            <div className="inner">
                <h1>CONTACT
                    <span>- お問い合わせ</span>
                </h1>
                <div className="contactSelect">
                    <form
                        method="post"
                        netlify-honeypot="bot-field"
                        data-netlify="true"
                        name="contact"
                        action="/contact-thank-you"
                    >
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="contact" />
                        <div className="formGroup">
                            <label>
                                <p>お名前<span>必須</span></p>
                                <input type="text" className="form-control" id="name" name="お名前" maxlength="30" minlength="2" required/>
                            </label>
                        </div>
                        <div className="formGroup">
                            <label>
                                <p>会社名<span>必須</span></p>
                                <input type="text" className="form-control" id="company-name" name="会社名" maxlength="30" minlength="2" required placeholder="個人事業主の方は「個人」とご記入ください"/>
                            </label>
                        </div>
                        <div className="formGroup">
                            <label>
                                <p>メールアドレス<span>必須</span></p>
                                <input type="email" className="form-control" id="email" name="email" required autocomplete="email" />
                            </label>
                        </div>
                        <div className="formGroup">
                            <label>
                                <p>電話番号</p>
                                <input type="text" className="form-control" id="tel" name="電話番号" autocomplete="tel" />
                            </label>
                        </div>
                        <div className="formGroup">
                            <label>
                                <p>相談方法</p>
                                <select name="way[]" >
                                <option value="オンライン">オンライン</option>
                                <option value="対面">対面</option>
                                </select>
                            </label>
                        </div>
                        <div className="formGroup">
                            <label>
                                <p>お問い合わせ項目<span>必須</span></p>
                                <input type="text" className="form-control" id="title" name="お問い合わせ項目" required />
                            </label>
                        </div>
                        <div className="formGroup">
                            <label>
                                <p>お問い合わせ内容</p>
                                <textarea className="form-control" id="contenct" name="お問い合わせ内容" rows="8" required placeholder="メッセージを入力する"></textarea>
                            </label>
                        </div>
                        <div className="formButton">
                            <button type="submit">送信</button>
                        </div>
                    </form>
                </div>
            </div> 
            <div className="breadCrumb">
                <ol>
                    <li>
                    <Link to={`/`}>TOP</Link>
                    </li>
                    <li>
                        about - このサイトについて
                    </li>
                </ol>
            </div>
        </main>
    </Layout>
)